import { Environment } from '@type/common'

export const API_ADDRESS = import.meta.env.VITE_API_URL
export const CLIENT_ID = import.meta.env.VITE_MSTEAMS_CLIENT_ID || ''

const ORIGIN = window.location.origin
export const START_LOGIN_PAGE = `${ORIGIN}/msteams/auth-start.html`
export const LOGIN_REDIRECT_URL = `${ORIGIN}/msteams/config.html`
export const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL
export const ENV = (import.meta.env.VITE_ENVIRONMENT as Environment) || Environment.local
export const APP_ID = import.meta.env.VITE_MSTEAMS_APP_ID || ''

export const ROLES_FOR_CONSENT = [
  '660b7406-55f1-41ca-a0ed-0b035e182f3e',
  '9ce09611-f4f7-4abd-a629-a05450422a97',
  '74ef0291-ca83-4d02-8c7e-d2391e6a444f',
  '1f615aea-6bf9-4b05-84bd-46388e138537',
  'd9c48af6-9ad9-47ad-82c3-63757137b9af',
  'dfb0dd15-61de-45b2-be36-d6a69fba3c79',
]

export const PAGE_ID = 'approveit-tab'

export const APPROVEIT_MS_LOGIN_URL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=code&scope=User.Read+offline_access+openid+profile`
export const CONSENT_URL = `https://login.microsoftonline.com/common/adminconsent?client_id=${CLIENT_ID}&redirect_uri=${LOGIN_REDIRECT_URL}`

const tabNames = {
  [Environment.local]: '[Local] Approveit',
  [Environment.dev]: '[Dev] Approveit',
  [Environment.staging]: '[Staging] Approveit',
  [Environment.production]: 'Approveit',
}

export const TAB_NAME = tabNames[ENV]

export const SUPPORT_EMAIL = 'support@approveit.today'
