import approveitRoutes from '@src/Routes/routes'

export default {
  public: {
    auth: '/auth',
    ...approveitRoutes.public,
  },
  private: {
    tab: '/tab',
    notSynchronized: '/not-synchronized',
    config: '/config',
    ...approveitRoutes.private,
  },
}
