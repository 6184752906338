export enum LocalStorageKey {
  mstoken = 'mstoken',
  token = 'token',
  refreshToken = 'refreshToken',
  mstokenExpiresOn = 'mstokenExpiresOn',
}

export enum AppScope {
  personal = 'personal',
  team = 'team',
}

export enum DeepLinkEntityType {
  request = 'request',
  task = 'task',
}
