import { createContext } from 'react'
import { AppScope, DeepLinkEntityType } from '@msteams/types/common'

export interface ContextValue {
  deepLinkEntityId?: string

  setDeepLinkEntityId(id?: string): void

  deepLinkEntityType?: DeepLinkEntityType

  scope?: AppScope

  setScope(scope: AppScope): void
}

const Default: ContextValue = {
  deepLinkEntityId: undefined,
  setDeepLinkEntityId: (id) => undefined,
  deepLinkEntityType: undefined,

  scope: undefined,
  setScope: (scope) => undefined,
}

export const GlobalContext = createContext<ContextValue>(Default)
export default GlobalContext
